<template>
  <section class="rj-invoice-modal">
    <header class="p-4 flex items-center justify-end no-print">
      <vs-button
        v-if="isAdmin"
        type="border"
        @click="togglePaymentModal"
      >
        Payment
      </vs-button>
      <vs-button
        class="ml-3"
        type="border"
        @click="download"
      >
        Download
      </vs-button>

      <vs-button
        class="ml-3"
        @click="print"
      >
        Print
      </vs-button>
    </header>
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
    >
      <main
        v-if="commissionStatement"
        id="invoice-page"
        slot="pdf-content"
        ref="commissionStatement"
        class="max-w-lg mx-auto"
      >
        <div id="invoice-container">
          <!-- INVOICE METADATA -->
          <div class="vx-row leading-loose p-base">
            <div class="vx-col w-1/2 mt-base">
              <img
                src="/rj-logo.png"
                alt="RevenueJump Logo"
                class="w-48"
              >
            </div>

            <div class="vx-col w-1/2 text-right">
              <h1>Commission Statement</h1>
              <div class="invoice__invoice-detail mt-6">
                <p>#{{ commissionStatement.number }} - {{ date }}</p>
              </div>
            </div>

            <div class="vx-col w-full mt-4 my-4">
              <div class="flex justify-between">
                <span />
                <p class="font-bold">
                  PAID TO
                </p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.addressLine1 }}</p>
                <p>{{ commissionStatement.partner.addressName || commissionStatement.partner.name }}</p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.addressLine2 }} {{ companyDetails.zipcode }}</p>
                <p>{{ commissionStatement.partner.addressStreet }}</p>
              </div>
              <div class="flex justify-between">
                <p>{{ companyDetails.mailId }}</p>
                <p>{{ commissionStatement.partner.addressCity }} {{ commissionStatement.partner.addressRegion }} {{ commissionStatement.partner.addressZip }}</p>
              </div>
            </div>

            <!-- INVOICE CONTENT -->
            <div class="mt-3 w-full">
              <table class="w-full">
                <thead class="border-b-2 border-gray-400">
                  <tr>
                    <th class="py-1 px-3 m-0 w-full">
                      COMPANY
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      AMOUNT
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      COMMISSION
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      BONUS
                    </th>
                    <th class="py-1 px-3 m-0 text-right">
                      SUBTOTAL
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="(item, index) in commissionStatement.commissionStatementItems">
                    <tr :key="index">
                      <td class="py-1 px-3">
                        <p>{{ item.company.name }}</p>
                      </td>
                      <td class="py-1 px-3 text-right">
                        <p>{{ formatCentsAsDollars(item.amount) }}</p>
                      </td>
                      <td class="py-1 px-3 text-right">
                        <p>{{ formatCentsAsDollars(item.commission) }}</p>
                      </td>
                      <td class="py-1 px-3 text-right">
                        <p>{{ formatCentsAsDollars(item.bonus) }}</p>
                      </td>
                      <td class="py-1 px-3 text-right">
                        <p>{{ formatCentsAsDollars(item.subtotal) }}</p>
                      </td>
                    </tr>
                  </template>
                  <!-- <tr
                    v-if="discount"
                    class="border-t-2 border-gray-400"
                  >
                    <td class="py-1 px-3">
                      <p>{{ discount.name }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ formatCentsAsDollars((-1) * discount.amount) }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ formatCentsAsDollars(discount.commissionDiff) }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ formatCentsAsDollars(discount.bonusDiff) }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ formatCentsAsDollars(discount.commissionDiff + discount.bonusDiff) }}</p>
                    </td>
                  </tr> -->
                </tbody>

                <tfoot class="border-t-2 border-gray-400">
                  <tr>
                    <td class="py-1 px-3">
                      <p>TOTALS</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ formatCentsAsDollars(totalAmount) }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ formatCentsAsDollars(commissionStatement.commissionAmount) }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <p>{{ formatCentsAsDollars(commissionStatement.bonusAmount) }}</p>
                    </td>
                    <td class="py-1 px-3 text-right">
                      <span class="text-2xl font-bold">{{ formatCentsAsDollars(commissionStatement.total) }}</span>
                    </td>
                  </tr>
                </tfoot>
              </table>

              <div class="flex justify-between py-2 mt-2">
                <div class="w-7/12 space-y-2 mr-4">
                  <div class="leading-tight">
                    <span class="font-semibold">Commission Rate:</span> {{ commissionRate }}
                  </div>

                  <div class="leading-tight">
                    <span class="font-semibold">Elite Partner Bonus:</span> {{ bonusRate }}
                  </div>

                  <div class="leading-tight">
                    <span class="font-semibold">Currency:</span> USD
                  </div>

                  <div class="leading-tight">
                    <p class="font-semibold">
                      Terms of Service:
                    </p>
                    <a
                      href="https://www.revenuejump.com/terms-of-service"
                      target="_blank"
                    >
                      https://www.revenuejump.com/terms-of-service
                    </a>
                  </div>
                </div>

                <div class="w-5/12 space-y-2 text-right">
                  <div class="leading-tight text-xl" />

                  <div class="leading-tight">
                    <span class="font-semibold">Status:</span>
                    {{ commissionStatement.status }} {{ commissionStatement.status === 'Paid' ? `${paidAt} by ${commissionStatement.paymentMethod}` : '' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </vue-html2pdf>
    <rj-commission-statement-status-modal
      v-if="paymentModalOpen"
      :active.sync="paymentModalOpen"
      :commission-statement="commissionStatement"
      @updated="onUpdate"
    />
  </section>
</template>

<script>
import Color from 'color';
import { format } from 'date-fns';
import VueHtml2pdf from 'vue-html2pdf';
import { formatCentsAsDollars } from '@/utils';
import Partner from '@/http/middleware/Partner';
import Auth from '@/http/middleware/Authenticate';
import { colors as themeColors } from '@/../themeConfig';
import RjCommissionStatementStatusModal from '@/views/admin-dashboard/partners/RjCommissionStatementStatusModal.vue';

export default {
  name: 'RjCommissionStatement',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    VueHtml2pdf,
    RjCommissionStatementStatusModal,
  },
  middleware: [Auth, Partner],

  data() {
    return {
      Color,
      themeColors,
      commissionStatement: null,
      companyDetails: {
        name: 'RevenueJump',
        addressLine1: '6700 N Linder Rd #156-143',
        addressLine2: 'Meridian, ID',
        zipcode: '83646',
        mailId: 'support@revenuejump.com',
        mobile: '',
      },
      paymentModalOpen: false,
    };
  },

  computed: {
    bonusRate() {
      if (!this.commissionStatement) {
        return '';
      }
      if (!this.commissionStatement.bonusRate) {
        return '0%';
      }
      return `${this.commissionStatement.bonusRate * 100}%`;
    },
    commissionRate() {
      if (!this.commissionStatement) {
        return '';
      }
      return `${this.commissionStatement.commissionRate * 100}%`;
    },
    date() {
      return format(new Date(this.commissionStatement.created * 1000), 'MM/d/yyyy');
    },
    discount() {
      if (!this.commissionStatement || !this.commissionStatement.discountAmount) {
        return null;
      }

      const {
        discountAmount, commissionDiff, bonusDiff, coupon,
      } = this.commissionStatement;

      if (!coupon) {
        return null;
      }

      return {
        name: coupon.name,
        description: coupon.metadata.description,
        amount: discountAmount,
        commissionDiff,
        bonusDiff,
      };
    },
    filename() {
      if (!this.commissionStatement) {
        return 'RevenueJump-Commission-Statement';
      }
      return `RevenueJump-Commission-Statement-${this.commissionStatement.number}`;
    },
    isAdmin() {
      return /^Admin/.test(this.$store.getters['auth/currentRole']);
    },
    paidAt() {
      if (!this.commissionStatement?.paidAt) {
        return '';
      }
      return format(new Date(this.commissionStatement.paidAt * 1000), 'MM/d/yyyy');
    },
    totalAmount() {
      return this.commissionStatement.commissionStatementItems.reduce((acc, cur) => acc + cur.amount, 0);
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    formatCentsAsDollars,

    async fetch() {
      const commissionStatementId = this.$route.params.statement;
      const partnerId = this.$route.params.partner;
      this.commissionStatement = await this.$store.dispatch('partners/getCommissionStatement', { partnerId, commissionStatementId });
    },
    onUpdate() {
      this.fetch();
      this.$tabEvent.emit('commission-stmt-updated');
    },
    print() {
      window.print();
    },
    async download() {
      this.$refs.html2Pdf.generatePdf();
    },
    /**
     * Toggles the user modal visibility.
     *
     * @param  {Object} user
     * @return {void}
     */
    togglePaymentModal() {
      this.paymentModalOpen = !this.paymentModalOpen;
    },
  },
};
</script>

<style>
@media print {
  .no-print, .no-print * {
    display: none !important;
  }
}

.vue-html2pdf .layout-container {
  position: static !important;
  background-color: #fff !important;
  z-index: 0;
}
</style>
